var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("Offres")]),_c('br'),_c('br'),_c('v-menu',{attrs:{"offset-y":""}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"value":"","color":"#2382bf","type":"text","hide-no-data":"","placeholder":"Recherche Offre","prepend-icon":"mdi-database-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.offres.length!==0)?_c('v-card',{staticClass:"support-requests mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-0 white--text info"},[_c('p',{staticClass:"white--text"},[_vm._v("Liste des Offreshh")]),_c('br')]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"pl-2"},[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("Ticket")]),_c('th',{staticClass:"text-left"},[_vm._v("Prix Maintenance")]),_c('th',{staticClass:"text-left"},[_vm._v("Prix main d'oeuvre")]),_c('th',{staticClass:"text-left"},[_vm._v("Dureé")]),_c('th',{staticClass:"text-left"},[_vm._v("Date confirmation")]),_c('th',{staticClass:"text-left"},[_vm._v("Voir")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Facture")])])]),_c('tbody',_vm._l((_vm.offres),function(offre,key){return _c('tr',{key:key},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(offre.ticket_id["numero"]))]),_c('td',[_vm._v(_vm._s(offre.prixmain))]),_c('td',[_vm._v(" "+_vm._s(offre.prix_oeuvre)+" ")]),_c('td',[_vm._v(_vm._s(offre.duree))]),_c('td',[_vm._v(_vm._s(offre.date_confirmation))]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'voir_devi',
                            params: { id: offre._id },
                          }}},[_c('v-icon',{attrs:{"large":"","color":"blue darken-2"}},[_vm._v(" mdi-message-text ")])],1)],1),_c('td',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_reception=='en attente'),expression:"offre.etat_reception=='en attente'"}]},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.refusoffre(offre)}}},[_vm._v(" Decline "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-cancel ")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.acceptoffre(offre)}}},[_vm._v(" Accept "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-checkbox-marked-circle ")])],1)],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_confirmation=='confirmé'),expression:"offre.etat_confirmation=='confirmé'"}],staticStyle:{"color":"green","font-weight":"bold","font-size":"15px"}},[_vm._v("Confirmé ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_confirmation=='en attente'),expression:"offre.etat_confirmation=='en attente'"}],staticStyle:{"color":"blue","font-weight":"bold","font-size":"15px"}},[_vm._v(" En attente")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_confirmation=='refusé'),expression:"offre.etat_confirmation=='refusé'"}],staticStyle:{"color":"red","font-weight":"bold","font-size":"15px"}},[_vm._v(" Refusé")])]),_c('td',[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_confirmation
=='confirmé'),expression:"offre.etat_confirmation\n=='confirmé'"}],attrs:{"to":{
                            name: 'voir_facture',
                            params: { id: offre._id },
                          }}},[_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v(" mdi-cash ")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(offre.etat_confirmation
!=='confirmé'),expression:"offre.etat_confirmation\n!=='confirmé'"}],staticStyle:{"color":"gray","font-weight":"bold","font-size":"15px"}},[_vm._v(" Non Facturé ")])],1)])}),0)]},proxy:true}],null,false,2296785428)})],1)],1):_vm._e(),(_vm.offres.length==0)?_c('p',[_vm._v("Pas des Offres.... ")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }