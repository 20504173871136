var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Tickets")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"support-requests mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-0 white--text info"},[_c('p',{staticClass:"white--text"},[_vm._v("Liste des tickets")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"pl-2"},[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("Numero")]),_c('th',{staticClass:"text-left"},[_vm._v(" client")]),_c('th',{staticClass:"text-left"},[_vm._v("Revendeur")]),_c('th',{staticClass:"text-left"},[_vm._v("garantie")]),_c('th',{staticClass:"text-left"},[_vm._v("statut")]),_c('th',{staticClass:"text-left"},[_vm._v("Affecté par")]),_c('th',{staticClass:"text-left"},[_vm._v("voir")])])]),_c('tbody',_vm._l((_vm.tickets),function(ticket,key){return _c('tr',{key:key},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(ticket.numero))]),_c('td',[_vm._v(_vm._s(ticket.client_id["prenom"])+" "+_vm._s(ticket.client_id["name"]))]),_c('td',[_vm._v(_vm._s(ticket.typerevendeur))]),(ticket.etat_garantie === 'garantie')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" garantie ")])],1):(ticket.etat_garantie === 'nn garantie')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" nn garantie ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'clôturé et décharge recu'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" clôturé et décharge recu ")])]}},{key:"default",fn:function(dialog6){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous livré sans décharge ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" Livré sans décharge ? "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog6.value = false), _vm.updatelivrerticket(
                                                          ticket._id
                                                        )}}},[_vm._v(" Livrer ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog6.value = false}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})],1):_vm._e(),(
                                              ticket.etat ===
                                              'réparation terminé'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" réparation terminé ")])]}},{key:"default",fn:function(dialog5){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous cloturé ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" Terminer la cloture ? "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog5.value = false), _vm.updateclotureticket(
                                                          ticket._id
                                                        )}}},[_vm._v(" Modifier ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog5.value = false}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})],1):_vm._e(),(
                                              ticket.etat === 'livré sans décharge'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" Livré sans décharge ")])],1):_vm._e(),(
                                              ticket.etat === 'en_attente_pr'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" en attente PR ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'devis_en_attente_accord_reception_client'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" devis en attente accord réception client ")])],1):_vm._e(),(
                                              ticket.etat === 'en cours'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" diagnostic en cours ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'devis_attente_accord_client_final'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" devis en attente accord client final ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'en attente de facturation'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" en attente de facturation ")])],1):_vm._e(),(
                                              ticket.etat ==
                                              'cloturesansreponse' 
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" clôturer sans réponse ")])],1):_vm._e(),(
                                              ticket.etat ==
                                              'refusparréception' 
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" clôturer sans réponse ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'clotureetdechargerecu'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" clôturé et décharge reçu ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'ouverte'
                                            )?_c('td',{staticClass:"ma-3 ml-10"},[_vm._v("   "),_c('v-chip',{attrs:{"link":"","color":"secondary"}},[_vm._v(" Ouverte ")])],1):_vm._e(),(ticket.affectépar =='')?_c('td',[_c('v-btn',{attrs:{"fab":"","small":"","color":"cyan accent-1"},on:{"click":function($event){return _vm.addticket(ticket._id)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" affecté ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'rapportticket',
                          params: { id: ticket._id },
                        }}},[_c('v-icon',{attrs:{"large":"","color":"orange darken-2"}},[_vm._v(" mdi-arrow-up-bold-box-outline ")])],1)],1)])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }