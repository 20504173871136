var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("emplacements")]),_c('br'),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{attrs:{"to":"/addemplacement"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow mr-1",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Ajouter Emplacement")])],1)]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"value":"","color":"#2382bf","type":"text","hide-no-data":"","placeholder":"Recherche emplacement","prepend-icon":"mdi-database-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card',{staticClass:"support-requests mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-0 white--text info"},[_c('p',{staticClass:"white--text"},[_vm._v("Liste des emplacements")]),_c('br'),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"pl-2"},[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("code")]),_c('th',{staticClass:"text-left"},[_vm._v("Nom")]),_c('th',{staticClass:"text-left"},[_vm._v("Type")]),_c('th',{staticClass:"text-left"},[_vm._v("produits")]),_c('th',{staticClass:"text-left"},[_vm._v("capacité")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.searchFunction),function(emplacement,index){return _c('tr',{key:index},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(emplacement.code))]),_c('td',[_vm._v(_vm._s(emplacement.nom))]),_c('td',[_vm._v(" "+_vm._s(emplacement.type)+" ")]),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'detailsproduits',
                          params: { id: emplacement._id },
                        }}},[_c('v-icon',{attrs:{"color":"#2382bf"}},[_vm._v(" mdi-eye ")])],1)],1),_c('td',[_vm._v(_vm._s(emplacement.capacite))]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                                  name: 'editemplacement',
                                  params: { id: emplacement._id },
                                }}},[_vm._v(" Modifier ")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',[_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(" Supprimer ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Supprimer Emplacement? ")]),_c('v-card-text',[_vm._v("Voulez vous supprimer cet emplacement ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.deletemplacement(emplacement._id)}}},[_vm._v(" Confirmer ")])],1)],1)],1)],1)],1)],1)])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }