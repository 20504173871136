var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Tickets Diagnostics")]),_c('v-menu',{attrs:{"offset-y":""}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"support-requests mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-2 pb-0 white--text info"},[_c('p',{staticClass:"white--text"},[_vm._v("Liste des tickets")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"pl-0"},[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("Numero")]),_c('th',{staticClass:"text-left"},[_vm._v("Revendeur")]),_c('th',{staticClass:"text-left"},[_vm._v("Garantie")]),_c('th',{staticClass:"text-left"},[_vm._v("Statut")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.displayedTickets),function(ticket,key){return _c('tr',{key:key},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(ticket.numero))]),_c('td',[_vm._v(_vm._s(ticket.revendeur))]),(ticket.etat_garantie === 'garantie')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" garantie ")])],1):(ticket.etat_garantie === 'nn garantie')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"error"}},[_vm._v(" non garantie ")])],1):_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" en attente ")])],1),(
                                              ticket.etat ===
                                              'réparation en cours'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},'v-chip',attrs,false),on),[_vm._v(" réparation en cours ")])]}},{key:"default",fn:function(dialog4){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous terminé la réparation ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" Terminer la réparation "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog4.value = false), _vm.updatereparationticket(
                                                          ticket._id
                                                        )}}},[_vm._v(" Modifier ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog4.value = false}}},[_vm._v(": Annuler ")])],1)],1)]}}],null,true)})],1):_vm._e(),(
                                              ticket.etat ===
                                              'en attente de facturation'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},'v-chip',attrs,false),on),[_vm._v(" en attente de facturation ")])]}},{key:"default",fn:function(dialog8){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous facturé le devis ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" êtes vous sure ? "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog8.value = false), _vm.updatedevisfactur(
                                                          ticket._id
                                                        )}}},[_vm._v(" Oui ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog8.value = false}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})],1):(ticket.etat === 'en cours')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"secondary"}},[_vm._v(" En cours de diagnostic ")])],1):_vm._e(),(ticket.etat === 'PRreçu')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"color":"success"}},[_vm._v(" PR Reçu ")])],1):(ticket.etat === 'ouverte')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" Ouverte ")])],1):(
                        ticket.etat ===
                        'devis_en_attente_accord_reception_client'
                      )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" devis en attente accord réception client ")])],1):(ticket.etat === 'diagnostic_en_cours')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" diagnostic en cours ")])],1):(
                        ticket.etat === 'devis_attente_accord_client_final'
                      )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" devis en attente accord client final ")])],1):(ticket.etat === 'cloturesansreponse' )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" clôturer sans réponse ")])],1):(ticket.etat === 'refusparréception' )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" clôturer sans réponse ")])],1):_vm._e(),(
                                              ticket.etat ===
                                              'clôturé et décharge recu'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" clôturé et décharge recu ")])]}},{key:"default",fn:function(dialog6){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous livré sans décharge ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" Livré sans décharge ? "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog6.value = false), _vm.updatelivrerticket(
                                                          ticket._id
                                                        )}}},[_vm._v(" Livrer ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog6.value = false}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})],1):_vm._e(),(
                                              ticket.etat ===
                                              'réparation terminé'
                                            )?_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},'v-chip',attrs,false),on),[_vm._v(" réparation terminé ")])]}},{key:"default",fn:function(dialog5){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Voulez vous cloturé ? ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('br'),_vm._v(" Terminer la cloture ? "),_c('br'),_c('br')])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"info","disabled":_vm.valid == false},on:{"click":function($event){(dialog5.value = false), _vm.updateclotureticket(
                                                          ticket._id
                                                        )}}},[_vm._v(" Modifier ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog5.value = false}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})],1):_vm._e(),(
                                              ticket.etat === 'livré sans décharge'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" Livré sans décharge ")])],1):(
                                              ticket.etat === 'en_attente_pr'
                                            )?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" en attente PR ")])],1):_vm._e(),_c('td',[(
                          ticket.etat == 'clôturé et décharge recu' ||
                          ticket.etat == 'cloturesansreponse' ||
                          ticket.etat == 'refusparréception'||
                          ticket.etat == 'livré sans décharge'||
                          ticket.etat == 'en attente de facturation'||
                          ticket.etat == 'devis_attente_accord_client_final'||
                          ticket.etat == 'devis_en_attente_accord_reception_client'
                          ||ticket.etat == 'réparation en cours'
                          ||ticket.etat == 'réparation terminé'||
                          ticket.etat == 'en cours'||
                          ticket.etat == 'facturé'
                        )?_c('router-link',{attrs:{"to":{
                          
                        }}},[_c('v-btn',{staticClass:"ma-9",attrs:{"color":"green","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-wrench ")])],1)],1):(
                          ticket.etat == 'en_attente_pr' ||  ticket.etat == 'PRreçu'
                      
                        )?_c('router-link',{attrs:{"to":{
                          name: 'viewdiagnosticenattentepr',
                          params: { id: ticket._id },
                        }}},[_c('v-btn',{staticClass:"ma-9",attrs:{"color":"red","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-wrench ")])],1)],1):_c('router-link',{attrs:{"to":{
                          name: 'viewticketdiagnostic',
                          params: { id: ticket._id },
                        }}},[_c('v-btn',{staticClass:"ma-9",attrs:{"color":"blue","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-wrench ")])],1)],1)],1)])}),0)]},proxy:true}])}),_c('div',{staticClass:"pagination"},[_c('button',{attrs:{"disabled":_vm.currentPage === 1},on:{"click":_vm.previousPage}},[_vm._v("Previous")]),_c('span',[_vm._v(_vm._s(_vm.currentPage))]),_c('button',{attrs:{"disabled":_vm.currentPage === _vm.totalPages},on:{"click":_vm.nextPage}},[_vm._v("Next")])])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }