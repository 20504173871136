<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Bon de réception</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/addticket">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                class="text-capitalize button-shadow mr-1"
                >Ajouter Bon de réception</v-btn
              >
            </router-link>
          </template>
        </v-menu>
      </v-row>
      
      <v-row>
        


        <v-text-field
              v-model="search"
              value=""
              color="#2382bf"
              type="text"
              hide-no-data
              placeholder="Recherche Par nom"
              prepend-icon="mdi-database-search"
            ></v-text-field>



        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0 white--text info">
              <p class="white--text">Liste des Bon de réceptions</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-9">
                    <tr>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <!-- <th class="text-left pa-6">Numero</th> -->
                            <th class="text-left pa-6">Client</th>
                            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                            &nbsp;&nbsp; &nbsp;
                            <th class="text-left pa-4">adresse mail</th>

                            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                            <th class="text-left pa-2">Telephone</th>
                            <th class="text-left pa-2">Date réception</th>

                            <th>Fiche réception</th>
                          </v-expansion-panel-header>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(ticket, key) in displayedTickets" :key="key">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <td class="pa-0">
                              {{ ticket["client_id"].name }}
                              {{ ticket["client_id"].prenom }}
                            </td>
                            <td class="pa-0">
                              {{ ticket["client_id"].email }}
                            </td>

                            <td class="pa-0">
                              {{ ticket["client_id"].telephone }}
                            </td>

                            <td class="pa-0">
                              {{ ticket.datereception | formatDate }}
                            </td>

                            <td class="pa-6">
                              <router-link
                                :to="{
                                  name: 'viewticket',
                                  params: { id: ticket._id },
                                }"
                              >
                                <v-icon large color="blue darken-2">
                                  mdi-eye
                                </v-icon>
                              </router-link>
                            </td>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-container>
                              <v-layout>
                                <v-flex xs12 sm12>
                                  <v-card hover>
                                    <v-card-title>
                                      <h4 class="info--text">
                                        <v-icon class="info--text"
                                          >mdi-ticket-account</v-icon
                                        >
                                        Liste des Tickets
                                      </h4>
                                    </v-card-title>
                                    <v-card-text>
                                      <v-simple-table class="text-left pa-6">
                                        <thead>
                                          <th>numero</th>
                                          <th>date reception</th>
                                          <th>produit</th>
                                          <th>statut affectation</th>
                                          <th>etat</th>
                                          <th>garantie</th>
                                          <th>Rapport</th>
                                          <th>Actions</th>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(tickett, key) in ticket[
                                              'tickets'
                                            ]"
                                            :key="key"
                                          >
                                            
                                            <td>{{ tickett.numero }}</td>

                                            <td>
                                              {{
                                                tickett.datereception
                                                  | formatDate
                                              }}
                                            </td>

                                            <td>
                                              {{ tickett.modeleappareil }}
                                            </td>

                                            <td v-if="tickett.affectépar == null">
                                              <!-- <v-chip
                                                link
                                                color="danger"
                                                class="ma-2 ml-0"
                                                @click="dialog2 = true"
                                              >
                                                en attente
                                              </v-chip> -->
                                              <v-dialog width="900">
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-chip
                                            link
                                                color="danger"
                                                class="ma-2 ml-0"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                            en attente
                                            </v-chip>
                                          </template>
                                          <template v-slot:default="dialog2">
                                            <v-card>
                                              <v-card-title
                                                class="text-h5 grey lighten-2"
                                              >
                                                Affecter Ticket {{tickett.numero }} au Technicien 
                                              </v-card-title>

                                              <v-card-text>
                                                <v-form v-model="valid">
                                                  <br />

                                                  Veuillez choisir le technicien
                                                  :
                                                  <br />
                                                  <br />
                                                  <v-container>
                                                    <v-card-text>
                                                      <v-select
                                                        outlined
                                                        label="Techniciens"
                                                        v-model="affectépar"
                                                        :items="techniciens"
                                                        item-text="name"
                                                        item-value="_id"
                                                        return-object
                                                        
                                                      ></v-select>
                                                    </v-card-text>
                                                  </v-container>
                                                </v-form>
                                              </v-card-text>

                                              <v-divider></v-divider>

                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                 
                                                  @click="(dialog2.value = false), addtechnicien(
                                                            tickett._id
                                                          )"
                                                  style="float: right"
                                                  color="info"
                                                  :disabled="valid == false"
                                                >
                                                  Affecter
                                                </v-btn>
                                                <v-btn
                                                  color="error"
                                                  @click="dialog2.value = false"
                                                >
                                                  Annuler
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </template>
                                        </v-dialog>
                                             
                                            </td>
                                            <td v-else>
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                affecté par {{tickett.affectépar.name }} 
                                              </v-chip>
                                            </td>

                                           



<!-- réparation en cours et terminé  -->

                                            <td
                                            v-if="
                                                tickett.etat ===
                                                'réparation en cours'
                                              "
                                            >

                                            <v-dialog width="900">
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-chip
                                            link
                                                color="danger"
                                                class="ma-2 ml-0"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                            réparation en cours
                                            </v-chip>
                                          </template>
                                          <template v-slot:default="dialog4">
                                            <v-card>
                                              <v-card-title
                                                class="text-h5 grey lighten-2"
                                              >
                                               Voulez vous terminé la réparation ? 
                                              </v-card-title>

                                              <v-card-text>
                                                <v-form v-model="valid">
                                                  <br />

                                                  Terminer la réparation 
                                                  
                                                  <br />
                                                  <br />
                                                
                                                </v-form>
                                              </v-card-text>

                                              <v-divider></v-divider>

                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                 
                                                  @click="(dialog4.value = false), updatereparationticket(
                                                            tickett._id
                                                          )"
                                                  style="float: right"
                                                  color="info"
                                                  :disabled="valid == false"
                                                >
                                                  Modifier
                                                </v-btn>
                                                <v-btn
                                                  color="error"
                                                  @click="dialog4.value = false"
                                                >
                                                  Annuler
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </template>
                                        </v-dialog>
                                            </td>



                                            <td
                                            v-if="
                                                tickett.etat ===
                                                'clôturé et décharge recu'
                                              "
                                            >

                                            <v-dialog width="500">
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-chip
                                            link
                                                color="success"
                                                class="ma-2 ml-0"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                            clôturé et décharge recu
                                            </v-chip>
                                          </template>
                                          <template v-slot:default="dialog6">
                                            <v-card>
                                              <v-card-title
                                                class="text-h5 grey lighten-2"
                                              >
                                               Voulez vous livré sans décharge  ? 
                                              </v-card-title>

                                              <v-card-text>
                                                <v-form v-model="valid">
                                                  <br />

                                                  Livré sans décharge ?
                                                  
                                                  <br />
                                                  <br />
                                                
                                                </v-form>
                                              </v-card-text>

                                              <v-divider></v-divider>

                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                 
                                                  @click="(dialog6.value = false), updatelivrerticket(
                                                            tickett._id
                                                          )"
                                                  style="float: right"
                                                  color="info"
                                                  :disabled="valid == false"
                                                >
                                                  Livrer
                                                </v-btn>
                                                <v-btn
                                                  color="error"
                                                  @click="dialog6.value = false"
                                                >
                                                  Annuler
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </template>
                                        </v-dialog>

                                      
                                            </td>







                                            <td
                                            v-if="
                                                tickett.etat ===
                                                'réparation terminé'
                                              "
                                            >

                                            <v-dialog width="500">
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-chip
                                            link
                                                color="success"
                                                class="ma-2 ml-0"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                            réparation terminé
                                            </v-chip>
                                          </template>
                                          <template v-slot:default="dialog5">
                                            <v-card>
                                              <v-card-title
                                                class="text-h5 grey lighten-2"
                                              >
                                               Voulez vous cloturé  ? 
                                              </v-card-title>

                                              <v-card-text>
                                                <v-form v-model="valid">
                                                  <br />

                                                  Terminer la cloture ?
                                                  
                                                  <br />
                                                  <br />
                                                
                                                </v-form>
                                              </v-card-text>

                                              <v-divider></v-divider>

                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                 
                                                  @click="(dialog5.value = false), updateclotureticket(
                                                            tickett._id
                                                          )"
                                                  style="float: right"
                                                  color="info"
                                                  :disabled="valid == false"
                                                >
                                                  Modifier
                                                </v-btn>
                                                <v-btn
                                                  color="error"
                                                  @click="dialog5.value = false"
                                                >
                                                  Annuler
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </template>
                                        </v-dialog>

                                      
                                            </td>



                                            <td
                                              v-if="
                                                tickett.etat === 'livré sans décharge'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                              Livré sans décharge
                                              </v-chip>
                                            </td>


                                            <td
                                              v-if="
                                                tickett.etat === 'en_attente_pr'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                en attente PR
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'devis_en_attente_accord_reception_client'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                devis en attente accord
                                                réception client
                                              </v-chip>
                                            </td>
                                            <td
                                              v-if="
                                                tickett.etat === 'en cours'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                diagnostic en cours
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'devis_attente_accord_client_final'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                devis en attente accord client
                                                final
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'en attente de facturation'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                              en attente de facturation
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ==
                                                'cloturesansreponse' 
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                clôturer sans réponse
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ==
                                                'refusparréception' 
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                clôturer sans réponse
                                              </v-chip>
                                            </td>  


                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'clotureetdechargerecu'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                                clôturé et décharge reçu
                                              </v-chip>
                                            </td>

                                            <td
                                            v-if="
                                                tickett.etat ===
                                                'ouverte'
                                              "
                                              class="ma-3 ml-10"
                                            >
                                              &nbsp;
                                              <v-chip link color="secondary">
                                                Ouverte
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat_garantie ===
                                                'garantie'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                                garantie
                                              </v-chip>
                                            </td>
                                            <td
                                              v-else-if="
                                                tickett.etat_garantie ===
                                                'nn garantie'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="secondary"
                                                class="ma-2 ml-0"
                                              >
                                                nn garantie
                                              </v-chip>
                                            </td>
                                            <td
                                              v-else-if="
                                                tickett.etat_garantie === ''
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                pas encore
                                              </v-chip>
                                            </td>

                                            <td>
                                              <router-link
                                                :to="{
                                                  name: 'rapportticket',
                                                  params: { id: tickett._id },
                                                }"
                                              >
                                                <v-icon large color="green ">
                                                  mdi-book
                                                </v-icon>
                                              </router-link>
                                            </td>

                                            <td>
                                              <v-menu>
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    <v-icon color="textColor"
                                                      >mdi-dots-vertical</v-icon
                                                    >
                                                  </v-btn>
                                                </template>

                                                <v-list>
                                                  <v-list-item
                                                    v-if="
                                                      tickett.affectépar == null
                                                    "
                                                  >
                                                    <v-list-item-title
                                                      ><router-link
                                                        :to="{
                                                          name: 'editticket',
                                                          params: {
                                                            id: tickett._id,
                                                          },
                                                        }"
                                                      >
                                                        modifier
                                                      </router-link></v-list-item-title
                                                    >
                                                  </v-list-item>
                                                  <v-dialog
                                                    v-model="dialog"
                                                    persistent
                                                    max-width="390"
                                                  >
                                                    <template
                                                      v-slot:activator="{
                                                        on,
                                                        attrs,
                                                      }"
                                                    >
                                                      <v-list-item
                                                        v-if="
                                                          tickett.affectépar ==
                                                          null
                                                        "
                                                      >
                                                        <v-list-item-title
                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          supprimer
                                                        </v-list-item-title>
                                                      </v-list-item>
                                                    </template>
                                                    <v-card>
                                                      <v-card-title
                                                        class="text-h5"
                                                      >
                                                        Supprimer Ticket?
                                                      </v-card-title>
                                                      <v-card-text
                                                        >Voulez vous supprimer
                                                        cet ticket
                                                        ?</v-card-text
                                                      >
                                                      <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                          color="blue"
                                                          text
                                                          @click="
                                                            dialog = false
                                                          "
                                                        >
                                                          Annuler
                                                        </v-btn>
                                                        <v-btn
                                                          color="red"
                                                          text
                                                          @click="
                                                            deleteticket(
                                                              tickett._id
                                                            )
                                                          "
                                                        >
                                                          Confirmer
                                                        </v-btn>
                                                      </v-card-actions>
                                                    </v-card>
                                                  </v-dialog>
                                                </v-list>
                                              </v-menu>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </v-simple-table>
                                    </v-card-text>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="pagination">
      <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
      <span>{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      itemsPerPage: 5,
      currentPage: 1,
      tickets: [],
      search: "",

      dialog: false,
      user: {},
      dialog2: true,
      dialog4: true,

      techniciens: [],
      affectépar: "",
      listetickets: {},
      champRules: [
        (v) => !!v || "Ce champ est obligatoire",
      ],
    };
  },

  computed: {
    searchFunction() {
      return this.tickets.filter((item) => {
        console.log(item.tickets);
        return (
          item.client_id["name"].toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    

    totalPages() {
      return Math.ceil(this.tickets.length / this.itemsPerPage);
    },
    displayedTickets() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.tickets.slice(startIndex, endIndex);
    },
    
  },
  created() {
    this.gettickets();
    this.getuser();
    this.getechniciens();
  },

  methods: {

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    handleAction(ticket) {
        // Handle the action based on the ticket data
        // For example, you can perform a specific action when the button is clicked
        console.log('Action clicked for ticket:', ticket);
      },
    getechniciens() {
      HTTP.get("users/getechniciens")
        .then((response) => {
          this.techniciens = response.data;
          console.log(this.techniciens);
        })

        .then((err) => console.log(err));
    },

    getuser() {
      var token = localStorage.getItem("token");
      HTTP.get("auth/getcurrentuser/" + token)
        .then((response) => {
          this.user = response.data;
        })
        .then((err) => console.log(err));
    },

    gettickets() {
      HTTP.get("tickets/getticket")
        .then((response) => {
          this.tickets = response.data;
        })

        .then((err) => console.log(err));
    },

    deleteticket(id) {
      HTTP.delete("tickets/deleteticket/" + id).then((response) => {
        console.log(response);
        this.$toast.success("Ticket supprimé!", {
          position: "top-right",
        });

        location.reload();
      });
    },

    addtechnicien(ticket) {
      console.log(ticket);
      let oldticket = {
        affectépar: this.affectépar._id,
        _id: ticket,
      };

      HTTP.put("tickets/updateticketechnicien", oldticket).then(() => {
        this.$toast.success("Ticket affecté!", {
          position: "top-right",
        });

        location.reload();
      });

     
    },

    

    updateclotureticket(ticket) {
      console.log(ticket);
      let oldticket = {
        
        _id: ticket,
      };

      HTTP.put("tickets/updateclotureticket", oldticket).then(() => {
        this.$toast.success("Ticket cloturé et décharge reçu!", {
          position: "top-right",
        });

        location.reload();
      });

     
    },

    


    updatelivrerticket(ticket) {
      let oldticket = {
        
        _id: ticket,
      };

      HTTP.put("tickets/updatelivrerticket", oldticket).then(() => {
        this.$toast.success("Ticket livré sans décharge!", {
          position: "top-right",
        });

        location.reload();
      });

     
    },



    updatereparationticket(ticket) {
      console.log(ticket);
      let oldticket = {
        
        _id: ticket,
      };

      HTTP.put("tickets/updatereparationticket", oldticket).then(() => {
        this.$toast.success("Ticket Réparé!", {
          position: "top-right",
        });

        location.reload();
      });

     
    },


  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
